import { Button, ButtonSubmit, Modal, Input, IcFluentSend24Regular } from '@flipgrid/flipkit';
import { useFetcher } from '@remix-run/react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import QrCodeReader from '../Buttons/QrCodeReader';
import resourceRoutes from '~/constants/resourceRoutes';

import type { OnRequestCloseType } from '@flipgrid/flipkit';
import type { ChangeEvent } from 'react';

type Props = {
  fre?: boolean;
  onRequestClose?: OnRequestCloseType;
};

const JoinCodeInput = ({ fre = false, onRequestClose }: Props) => {
  const { t } = useTranslation(); // TODO: Fix translation keys
  const fetcher = useFetcher();
  const codeInput = useRef<HTMLInputElement>(null);
  const [error, setError] = useState('');
  const [enable, setEnable] = useState(false);

  useEffect(() => {
    if (fetcher.data?.status === 403) {
      window.open(`/${fetcher.data.joinCode}`);
      if (onRequestClose) onRequestClose();
    }
    if (fetcher.data?.status === 404 && fetcher.data.joinCode === codeInput.current?.value) {
      setError(t('shared.canNotFindResource'));
    }
    // user is allowed into group
    if (onRequestClose && fetcher.data === undefined && fetcher.type === 'actionRedirect') onRequestClose();
    // we only want this to fire once, and the fetcher appears to update when the activity fetcher updates
  }, [fetcher.data, fetcher.type, onRequestClose, t]);

  const validateQRScan = (result: string) => {
    if (result && result.indexOf('flipgrid') === -1 && result.indexOf('flip') === -1) {
      return t('joinCodeModal.invalidCode');
    }

    return null;
  };

  const handleQRScan = (result: string) => {
    const formData = new FormData();
    if (result.includes('/s/')) {
      window.open(result);
    } else {
      const token = new URL(result).pathname.substring(1);
      formData.append('join_code', token);
      fetcher.submit(formData, { method: 'post', action: resourceRoutes.joinCode });
    }
  };

  const handleFlipCodeInput = (e: ChangeEvent<HTMLFormElement>) => {
    const formData = new FormData(e.currentTarget);
    const inputValue = formData.get('join_code');
    // allow + as a first character, otherwise blocks special characters
    const joinCodeRegex = /^\+?([A-Za-z0-9]+)?$/;

    let errorMessage = '';
    if (typeof inputValue === 'string' && !joinCodeRegex.test(inputValue)) {
      // matches alphanumeric and + characters
      const alphaNumericPlusRegex = /[A-Za-z0-9+]/;

      // create a string of unique string for characters that do not match alphaNumericPlusRege
      const invalidCharacters = [...new Set([...inputValue])]
        .filter(char => !alphaNumericPlusRegex.test(char))
        .join('');

      // if invalid characters exist, update the error message to display those invalid characters
      if (invalidCharacters.length > 0) {
        errorMessage = t('shared.joinCodeInvalidCharacters', { characters: invalidCharacters });
      }

      // if the user adds a + after the first character, add the error message for invalid + characters
      if (inputValue.lastIndexOf('+') > 0) {
        const shouldAppendPlusErrorAsNewSentence = errorMessage.length > 0;
        errorMessage = shouldAppendPlusErrorAsNewSentence
          ? t('shared.invalidCharactersAtTheStart', { characters: invalidCharacters })
          : t('shared.plusOnlyValidAtStart');
      }
    }
    setEnable(typeof inputValue === 'string' && inputValue.length > 0 && !errorMessage);
    setError(errorMessage);
  };

  return (
    <fetcher.Form onChange={handleFlipCodeInput} method="post" action={resourceRoutes.joinCode}>
      <Input
        name="join_code"
        inputButton={
          fre ? (
            <Button
              type="submit"
              data-testid="joinCodeInput__button__go"
              aria-label={t('common.letsGo')}
              icon={<IcFluentSend24Regular />}
              theme="secondary"
            />
          ) : (
            <QrCodeReader inModal validate={validateQRScan} handleQrScan={handleQRScan} />
          )
        }
        aria-label={t('joinCodeModal.enterToFind')}
        inputButtonLeft={fre ? <QrCodeReader validate={validateQRScan} handleQrScan={handleQRScan} /> : undefined}
        label={t('shared.enterJoinCode')}
        aria-required="true"
        placeholder={fre ? t('shared.enterJoinCode') : t('joinCodeModal.example')}
        floatingLabel
        ref={codeInput}
        error={error}
      />

      {onRequestClose && (
        <Modal.Actions className="mt2">
          <Button data-testid="joinCodeModal__button__close" theme="secondary" onClick={onRequestClose}>
            {t('common.cancel')}
          </Button>
          <ButtonSubmit
            data-testid="joinCodeModal__button__submit"
            theme="primary"
            disabled={!enable}
            loading={fetcher.state === 'submitting'}
          >
            {t('joinCodeModal.letsGo')}
          </ButtonSubmit>
        </Modal.Actions>
      )}
      <input type="hidden" name="joinCodeModal" value="true" />
    </fetcher.Form>
  );
};

export default JoinCodeInput;
