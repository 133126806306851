import { Modal } from '@flipgrid/flipkit';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import JoinCodeInput from '../Utility/components/JoinCodeInput';
import globalContext from '~/contexts/globalContext';

import type { OnRequestCloseType } from '@flipgrid/flipkit';

type Props = {
  fromIntentModal?: boolean;
  onRequestClose: OnRequestCloseType;
};

const JoinCodeModal = ({ fromIntentModal, onRequestClose }: Props) => {
  const { t } = useTranslation();
  const { setClosedIntentModal, setClosedJoin } = useContext(globalContext);
  const closeJoinCodeModal = () => {
    if (fromIntentModal) {
      setClosedIntentModal(false);
      setClosedJoin(true);
    }
    onRequestClose();
  };

  return (
    <Modal onClose={closeJoinCodeModal}>
      <h1 className="fk-modalHeader">{t('joinCodeModal.joinYourGroup')}</h1>
      <p className="fk-modalBody fk-mt0">{t('joinCodeModal.enterToFind')}</p>
      <JoinCodeInput onRequestClose={closeJoinCodeModal} />
    </Modal>
  );
};

export default JoinCodeModal;
